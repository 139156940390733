import React from "react";
import Slider from "react-slick";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";

import "../styles/page/encord-competitor.scss";
import ratingIcon from "../assets/g2-rating-icon.png";
import readMoreIcon from "../assets/g2-read-more-icon.png";
import forwardIcon from "../assets/forward-icon.png";
import sliderBackgroundImage from "../assets/slide-bg-image.png";
import ratingImage from "../assets/rating-image.png";
import outsourceImage from "../assets/outsource-image.png";
import tickIcon from "../assets/tick-icon.png";
import startCommas from "../assets/start-commas.png";
import endCommas from "../assets/end-commas.png";
import { newCardSliderSettings } from "../constants";
import NewLeadFormWithAPI from "../components/NewLeadFormWithAPI";
import { Helmet } from "react-helmet";
import InfiniteLooper from "../components/InfiniteLooper";
import NewHomeLeadFormWithAPIForm from "../components/NewHomeLeadFormWithAPI";
import VideoComponent from "../components/VideoComponent";

const SideCard = ({ img, subTitle, title, description, hrefLink, btnText }) => {
  return (
    <div className="lg:max-w-[547px] md:max-w-[700px] max-w-[400px] shadow-xl lg:rounded-2.5xl rounded-[10px] mx-auto lg:mb-0 md:mb-5 mb-10 lg:px-[36px] md:px-[25px] px-[20px] bg-[white] flex flex-col justify-between">
      <div className="lg:min-h-[250px] lg:max-w-[440px] mx-auto">
        <img
          src={img}
          alt="img"
          loading="lazy"
          className="w-auto h-auto"
          width={0}
          height={0}
        />
      </div>
      <div className="font-medium   text-gray-600 lg:text-[20px] md:text-[18px] text-base lg:mb-[15px] md:mb-[10px] mb-[5px]">
        {subTitle}
      </div>
      <div className="font-semibold   lg:text-[36px] md:text-[30px] text-2xl text-purple-1000 lg:mb-[15px] md:mb-[10px] mb-[5px]">
        {title}
      </div>

      <div className="text-gray-600   lg:text-[20px] md:text-[18px] text-base lg:py-[20px] md:py-[15px] py-[10px] lg:mb-[33px] md:mb-[20px] mb-[15px]">
        {description}
      </div>

      <a href={hrefLink} className="flex flex-row items-center mb-[18px]">
        <span className="  pr-2 lg:text-[22px] md:text-[19px] text-[17px] text-purple-500 font-bold">
          {btnText}
        </span>
        <img
          src={forwardIcon}
          alt="forward-icon"
          loading="lazy"
          className="w-auto h-auto"
          width={0}
          height={0}
        />
      </a>
    </div>
  );
};

const SideBySideSection = ({
  isHeaderText,
  headerText,
  title,
  description,
  isSubDescription,
  subDescriptionOne,
  subDescriptionTwo,
  hrefLink,
  sideImage,
  sideVideo,
  btnText,
  shadow,
  bRadius,
  isStartCommas,
  isEndCommas,
}) => {
  return (
    <div
      className="flex lg:flex-row md:flex-col flex-col lg:max-w-[1136px] md:max-w-[700px] max-w-[400px] mx-auto lg:p-[23px] md:p-[18px] p-[15px] lg:my-2 md:my-1 my-1 relative"
      style={{
        boxShadow: shadow ? shadow : "none",
        borderRadius: bRadius ? bRadius : "0px",
      }}
    >
      {isStartCommas && (
        <img
          src={startCommas}
          alt="img"
          loading="lazy"
          className="absolute lg:flex md:hidden hidden top-[50px] w-auto h-auto"
          width={0}
          height={0}
        />
      )}
      {isEndCommas && (
        <img
          src={endCommas}
          alt="img"
          loading="lazy"
          className="absolute top-[120px] right-[50%]  lg:flex md:hidden hidden w-auto h-auto"
          width={0}
          height={0}
        />
      )}
      <div className="flex flex-col justify-center">
        {isHeaderText && (
          <div className="lg:pb-[80px] md:pb-[50px] pb-[30px] lg:text-[20px] md:text-[18px] text-base text-gray-600  ">
            {headerText}
          </div>
        )}

        <div className="pb-[33px]   lg:text-[36px] lg:leading-9 md:text-[30px] text-2xl text-purple-1000">
          {title}
        </div>

        <div className="pb-[33px]   lg:text-[20px] md:text-[18px] text-base text-gray-600">
          {description}
        </div>

        {isSubDescription && (
          <div className="flex flex-col pb-[33px]">
            <div className="pb-[9px]   lg:text-[17px] md:text-[15px] text-sm font-semibold text-purple-1300">
              {subDescriptionOne}
            </div>
            <div className="  text-purple-1300 lg:text-[15px] md:text-[13px] text-xs">
              {subDescriptionTwo}
            </div>
          </div>
        )}

        <a href={hrefLink} className="flex flex-row items-center mb-[38px]">
          <span className="  pr-2 lg:text-[22px] md:text-[19px] text-[17px] text-purple-500 font-bold">
            {btnText}
          </span>
          <img
            src={forwardIcon}
            alt="forward-icon"
            loading="lazy"
            width={0}
            height={0}
            className="w-auto h-auto"
          />
        </a>
      </div>

      {sideVideo ? (
        <VideoComponent
          loop
          autoPlay
          muted
          className="max-w-[469px] md:mx-auto"
        >
          <source src={sideVideo} type="video/mp4" />
        </VideoComponent>
      ) : (
        <>
          <img
            src={sideImage}
            alt="single review"
            className="max-w-[469px] md:mx-auto w-full h-auto"
            loading="lazy"
            width={0}
            height={0}
          />
        </>
      )}
    </div>
  );
};

const RowByRowSection = ({
  isHeaderText,
  headerText,
  title,
  description,
  isSubDescription,
  subDescriptionOne,
  subDescriptionTwo,
  hrefLink,
  sideImage,
  sideVideo,
  btnText,
  shadow,
  bRadius,
}) => {
  return (
    <div
      className="flex lg:flex-row md:flex-col flex-col lg:max-w-[1136px] md:max-w-[700px] max-w-[400px] w-full mx-auto lg:p-[34px] p-[15px] relative lg:rounded-4xl md:rounded-2.5xl rounded-[10px]"
      style={{
        boxShadow: shadow ? shadow : "none",
        borderRadius: bRadius ? bRadius : "0px",
      }}
    >
      <div className="flex flex-col justify-between">
        {isHeaderText && (
          <div className="lg:pb-[80px] md:pb-[50px] pb-[30px] lg:text-[20px] md:text-[18px] text-base text-gray-600  ">
            {headerText}
          </div>
        )}

        <div className="pb-[33px]   lg:text-[36px] md:text-[30px] text-2xl lg:leading-9  text-purple-1000 font-semibold">
          {title}
        </div>

        <div className="pb-[33px]   lg:text-[20px] md:text-[18px] text-base text-gray-600">
          {description}
        </div>

        {isSubDescription && (
          <div className="flex flex-col pb-[33px]">
            <div className="pb-[9px]   lg:text-[17px] md:text-[15px] text-sm font-semibold text-purple-1300">
              {subDescriptionOne}
            </div>
            <div className="  text-purple-1300 lg:text-[15px] md:text-[13px] text-xs">
              {subDescriptionTwo}
            </div>
          </div>
        )}

        <a
          href={hrefLink}
          className="flex flex-row items-center lg:mb-0 mb-[20px]"
        >
          <span className="  pr-2 lg:text-[22px] md:text-[19px] text-[17px] text-purple-500 font-bold">
            {btnText}
          </span>
          <img
            src={forwardIcon}
            alt="forward-icon"
            loading="lazy"
            width={0}
            height={0}
            className="w-auto h-auto"
          />
        </a>
      </div>

      {sideVideo ? (
        <VideoComponent
          loop
          autoPlay
          muted
          className="max-w-[542px] md:mx-auto"
        >
          <source src={sideVideo} type="video/mp4" />
        </VideoComponent>
      ) : (
        <>
          <img
            src={sideImage}
            alt="img"
            className="max-w-[542px] md:mx-auto w-full h-auto"
            loading="lazy"
            width={0}
            height={0}
          />
        </>
      )}
    </div>
  );
};

const CustomTag = ({ borderColor, textColor, bgColor, customIcon, title }) => {
  return (
    <div
      className="flex flex-row items-center mx-auto md:w-auto w-full lg:max-w-[280px] md:max-w-[266px] max-w-[250px] lg:rounded-[10px] rounded-[5px] pl-[21px] pr-[18px] lg:mt-[60px] md:mt-[64px] mt-[20px] py-1 justify-center"
      style={{
        backgroundColor: `${bgColor}`,
        border: `2px solid ${borderColor}`,
      }}
    >
      <img
        src={customIcon}
        width={21}
        alt="img"
        loading="lazy"
        className="h-auto"
        height={0}
      />
      <div
        className="lg:text-base md:text-sm text-xs pl-[17px]   font-semibold uppercase"
        style={{ color: `${textColor}` }}
      >
        {title}
      </div>
    </div>
  );
};

const HeroSection = ({
  textColor,
  title,
  description,
  webPaddingTop,
  mobPaddingTop,
  isBiggerSize,
  isVideo,
}) => {
  return (
    <div className="max-w-[900px] mx-auto lg:px-4 md:px-3 px-2">
      <div
        className={`pt-${mobPaddingTop} pb-1 lg:pb-2 md:pt-${webPaddingTop}`}
      >
        <div
          className={`${
            isBiggerSize
              ? "heading_gradient_choose_encord"
              : "smaller_heading_gradient_choose_encord"
          }  relative text-center flex flex-col items-center`}
        >
          <div
            style={{ color: `${textColor}` }}
            className={`mb-2 lg:text-[50px] md:text-[35px] text-[25px] leading-9 sm:leading-[50px]   ${
              isBiggerSize
                ? "heading_gradient_choose_encord"
                : "smaller_heading_gradient_choose_encord"
            }  font-semibold`}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <div
            style={{ color: `${textColor}` }}
            className="pt-2 space-y-4 check_list ont-inter max-w-2xl lg:max-w-3xl text-base lg:text-xl 2xl:text-2xl leading-[25px] lg:leading-[35px] "
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="mt-4 lg:mt-8 space-y-4 min-w-[-webkit-fill-available] md:min-w-[612px]">
            <section className="flex justify-center">
              <NewHomeLeadFormWithAPIForm
                pageName={"Competitor Landing Page"}
                btnText="Book a demo"
                // isBtnColor={isBtnColor}
                location={"/"}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionalSection = ({
  textColor,
  title,
  description,
  webPaddingTop,
  mobPaddingTop,
  isBiggerSize,
  isMediumSize,
  isBtnColor,
  isVideo,
}) => {
  return (
    <div className="lg:max-w-[900px] md:max-w-[700px] mx-auto px-4">
      <div
        className={`pt-${mobPaddingTop} lg:pb-12 md:pt-${webPaddingTop} pb-6`}
      >
        <div className="relative flex flex-col items-center text-center">
          <div
            style={{ color: `${textColor}` }}
            className={`mb-2 ${
              isMediumSize
                ? "lg:text-[50px] md:text-[35px] text-[25px]"
                : "lg:text-[50px] md:text-[35px] text-[25px]"
            }   ${
              isBiggerSize
                ? "bigger_gradiant"
                : "sectional_gradient_choose_encord"
            } font-semibold lg:leading-[60px] md:leading-[40px] leading-[30px] normal-case`}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />

          <div
            style={{ color: `${textColor}` }}
            className="space-y-4 check_list ont-inter lg:text-[24px] md:text-[20px] text-base max-w-md lg:max-w-[770px] lg:leading-[35px] md:leading-[30px] leading-[25px]"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <div className="pt-4 lg:pt-6  space-y-4 min-w-[-webkit-fill-available] md:min-w-[612px]">
            <section className="flex justify-center">
              <NewHomeLeadFormWithAPIForm
                pageName={"Competitor Landing Page"}
                btnText="Book a demo"
                // isBtnColor={isBtnColor}
                location={"/"}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

const PageContent = ({ data }) => {
  const _data =
    data?.allPrismicNestedCompetitorLandingPage?.nodes &&
    data?.allPrismicNestedCompetitorLandingPage?.nodes[0]
      ? data?.allPrismicNestedCompetitorLandingPage?.nodes[0]?.data
      : {};

  const [trusted_brands_list] = _data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  ) || [[]];
  const [testimonial] = _data?.body?.filter(
    (v) => v.slice_type === "testimonial"
  );
  const [feature_table] = _data?.body?.filter(
    (v) => v.slice_type === "feature_table"
  );
  const [annotate_section] = _data?.body?.filter(
    (v) => v.slice_type === "annotate_section"
  );
  const [ai_powered_labeling] = _data?.body?.filter(
    (v) => v.slice_type === "ai-powered_labeling"
  );
  const [labelling_types] = _data?.body?.filter(
    (v) => v.slice_type === "labelling_types"
  );
  const [single_review] = _data?.body?.filter(
    (v) => v.slice_type === "single_review"
  );
  const [encord_active] = _data?.body?.filter(
    (v) => v.slice_type === "encord_active"
  );
  const [data_and_model_quality] = _data?.body?.filter(
    (v) => v.slice_type === "data_and_model_quality"
  );
  const [outsource] = _data?.body?.filter((v) => v.slice_type === "outsource");
  const [discover_label_errors] = _data?.body?.filter(
    (v) => v.slice_type === "discover_label_errors"
  );
  const [active_learning_pipelines] = _data?.body?.filter(
    (v) => v.slice_type === "active_learning_pipelines"
  );
  const [security_at_encord] = _data?.body?.filter(
    (v) => v.slice_type === "security_at_encord"
  );
  const [data_and_security] = _data?.body?.filter(
    (v) => v.slice_type === "data_and_security"
  );
  const [api_and_sdk] = _data?.body?.filter(
    (v) => v.slice_type === "api_and_sdk"
  );
  const [workflows] = _data?.body?.filter((v) => v.slice_type === "workflows");
  const [integrations] = _data?.body?.filter(
    (v) => v.slice_type === "integrations"
  );

  return (
    <section>
      <HeroSection
        textColor="#34344B"
        webPaddingTop={40}
        mobPaddingTop={32}
        title={_data?.page_heading?.html}
        description={_data?.page_description.text}
        isBiggerSize={_data?.hero_section_video?.url ? true : false}
        isVideo={!!_data?.hero_section_video?.url}
      />

      <article>
        <div className="relative overflow-x-auto mx-auto max-w-[966px] table_gradient  lg:p-[18px] md:p-[12px] p-[10px] rounded-[25px] mb-[90px]">
          {_data?.hero_section_video?.url ? (
            <VideoComponent
              width="100%"
              autoPlay={true}
              preload="auto"
              controls={false}
              loop
              muted
              className="mt-2 xl:w-[1358px] xl:h-[644px] object-cover rounded-md"
            >
              <source src={_data?.hero_section_video?.url} type="video/mp4" />
            </VideoComponent>
          ) : (
            <table className="w-full overflow-hidden text-sm text-left text-gray-500 shadow-xl rounded-xl">
              <thead className="text-xs text-gray-700 bg-[#F9F9FF] uppercase border-b">
                <tr>
                  <th
                    scope="col"
                    className="lg:pl-[60px] md:pl-[20px] pl-3 lg:pr-4 md:pr-3 pr-3 lg:py-6 md:py-4 py-3 lg:text-[20px] md:text-[18px] text-base text-[#464646]   capitalize"
                  >
                    {feature_table?.primary?.heading?.text}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 lg:px-6 md:px-4 lg:py-6 md:py-4 "
                    align="center"
                  >
                    <img
                      src={feature_table?.primary?.encord_logo?.url}
                      alt="encord-icon"
                      className="h-9 w-auto min-w-[36px]"
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                  </th>
                  <th
                    scope="col"
                    className="lg:pr-[60px] md:pr-[20px] pr-3 lg:pl-4 pl-3 lg:py-6 md:py-4 py-3 "
                    align="center"
                  >
                    <img
                      src={feature_table?.primary?.competitor_logo?.url}
                      alt="competitor logo"
                      className="min-w-[70px] w-[70px] h-auto"
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {feature_table?.items?.map(
                  ({ feature_name, competitor_score, encord_score }) => {
                    return (
                      <tr className="bg-white border-b">
                        <td className="lg:pl-[60px] md:pl-[20px] pl-3 lg:pr-4 md:pr-3 pr-3 lg:py-4 md:py-3 py-2   font-normal text-[black] lg:text-[16px] md:text-[14px] text-[12px] whitespace-nowrap">
                          {feature_name?.text}
                        </td>
                        <td
                          className="lg:px-6 md:px-4 px-3 lg:py-4 md:py-3 py-2   text-purple-500 lg:text-[14px] md:text-[12px] text-2xs"
                          align="center"
                        >
                          {encord_score?.text}
                        </td>
                        <td
                          class="lg:pr-[60px] md:pr-[20px] pr-3 lg:pl-4 pl-3 lg:py-4 md:py-3 py-2   text-gray-1200 lg:text-[14px] md:text-[12px] text-2xs"
                          align="center"
                        >
                          {competitor_score?.text}
                        </td>
                      </tr>
                    );
                  }
                )}
                <tr className="bg-white">
                  <td className="lg:pl-[60px] md:pl-[20px] pl-3 lg:pr-4 md:pr-3 pr-3 lg:py-4 md:py-3 py-2 font-medium text-[black] lg:text-[16px] md:text-[14px] text-[12px] whitespace-nowrap">
                    <a
                      href="https://www.g2.com/products/encord/reviews"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={readMoreIcon}
                        alt="g2-read-more-icon-icon"
                        loading="lazy"
                        className="max-w-[176px] w-full h-auto"
                        width={0}
                        height={0}
                      />
                    </a>
                  </td>
                  <td
                    className="px-3 py-2 lg:px-6 md:px-4 lg:py-4 md:py-3"
                    align="center"
                  >
                    <img
                      src={ratingIcon}
                      alt="g2-rating-icon"
                      loading="lazy"
                      className="w-auto h-auto min-w-[90px]"
                      width={0}
                      height={0}
                    />
                  </td>
                  <td class="lg:px-6 md:px-4 px-3 lg:py-4 md:py-3 py-2">
                    {""}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </article>

      <article className="linear_background">
        <section className="section_container mx-auto lg:py-9.5 md:py-[25px] py-[20px] space-y-[16px]">
          <section>
            <p className="text-center text-sm text-blue-1200   tracking-[0.07em]">
              {trusted_brands_list?.primary?.label?.text}
            </p>
          </section>
          <section className="grid grid-cols-2 gap-8 md:grid-cols-8">
            {trusted_brands_list?.items?.map((item, index) => {
              return (
                <div
                  key={`brand_${index}`}
                  className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1"
                >
                  <img
                    src={item?.brand_image?.url}
                    alt={item?.brand_image?.alt || "img"}
                    className={`${"object-scale-down h-auto w-auto"} img_grayscale`}
                    loading="lazy"
                    width={0}
                    height={0}
                  />
                </div>
              );
            })}
          </section>
        </section>
      </article>

      <article>
        <CustomTag
          bgColor="#FFFAFC"
          borderColor="#E087BB"
          customIcon={annotate_section?.primary?.label_icon?.url}
          textColor="#C41D7F"
          title={annotate_section?.primary?.label?.text}
        />

        <SectionalSection
          webPaddingTop={8}
          mobPaddingTop={8}
          isVideo={!!_data?.hero_section_video?.url}
          isBiggerSize={true}
          title={annotate_section?.primary?.heading?.html}
          description={annotate_section?.primary?.description?.text}
        />
      </article>

      <div className="flex lg:flex-row md:flex-col flex-col mx-auto max-w-[1136px] lg:my-[50px] md:my-[40px] my-[30px]">
        <div className="flex flex-col mr-[0px] overflow-hidden">
          <div className="flex flex-col rounded-2.5xl lg:max-w-[607px] max-w-[400px] w-full md:max-w-[700px]  bg-[#F2F2FF] lg:mb-[22px] md:mb-[20px] mb-[15px] pt-[30px] justify-between mx-auto overflow-hidden">
            <div className="  lg:text-[20px] md:text-[18px] text-base text-gray-600 lg:mb-[30px] md:mb-[20px] mb-[15px] px-[20px]">
              {ai_powered_labeling?.primary?.label?.text}
            </div>
            <div className="text-purple-1000 lg:text-[36px] md:text-[30px] text-2xl lg:leading-9   lg:mb-[30px] md:mb-[20px] mb-[15px] px-[20px]">
              {ai_powered_labeling?.primary?.heading?.text}
            </div>
            <div className="text-gray-600 lg:text-[20px] md:text-[18px] text-base   lg:mb-[30px] md:mb-[20px] mb-[15px] px-[20px]">
              {ai_powered_labeling?.primary?.description?.text}
            </div>
            <div className="text-purple-500 lg:text-[20px] md:text-[18px] text-base   mb-[10px] px-[20px]">
              {ai_powered_labeling?.primary?.learn_more_text?.text}
            </div>
            <div className="flex mb-[10px] lg:px-[20px] md:px-[15px] px-[15px] flex-row flex-wrap">
              {ai_powered_labeling?.primary?.tag?.text?.split(",")?.map((x) => {
                const [label, url] = x?.split("::");
                return (
                  <a
                    href={url}
                    key={label}
                    target="__blank"
                    className="lg:px-[22px] md:px-[17px] px-[15px] lg:py-[5px] md:py-[3px] py-[2px]   font-medium lg:text-[17px] md:text-[15px] text-[12px] text-[white] bg-[#5658DD] lg:mr-[9px] mr-[5px] cursor-pointer md:rounded-[5px] rounded-[5px] lg:mb-0 mb-[5px]"
                  >
                    <p className="text-xs font-medium tracking-wider lg:text-base ">
                      {label.toUpperCase()}
                    </p>
                  </a>
                );
              })}
            </div>
            <section className="marquee_wrapper pt-9">
              <InfiniteLooper speed="40" direction="right">
                {ai_powered_labeling?.items?.map((data, index) => {
                  return (
                    <img
                      key={index}
                      src={data?.slider_image?.url}
                      alt={data?.slider_image?.alt || "img"}
                      className="h-[132px] md:h-[136px] w-[132px] md:w-[136px] rounded-2.5xl ml-5"
                      width={0}
                      height={0}
                    />
                  );
                })}
              </InfiniteLooper>
              <InfiniteLooper speed="40" direction="left">
                {ai_powered_labeling?.items?.map((data, index) => {
                  return (
                    <img
                      key={index}
                      src={data?.slider_image?.url}
                      alt={data?.slider_image?.alt || "img"}
                      className="h-[132px] md:h-[136px] w-[132px] md:w-[136px] rounded-2.5xl ml-5"
                      width={0}
                      height={0}
                    />
                  );
                })}
              </InfiniteLooper>
            </section>
          </div>

          <div className="flex md:flex-row-reverse flex-col rounded-2.5xl lg:max-w-[607px] max-w-[400px] md:max-w-[700px] px-[20px] bg-[#EFEFFF] pt-[30px] lg:min-h-[393px] justify-between mx-auto w-full">
            <img
              src={outsourceImage}
              alt="img"
              loading="lazy"
              className="md:relative relative lg:mt-[40px] lg:mx-0 mx-auto lg:max-h-[240px] max-h-[200px] max-w-[200px] w-auto h-auto"
              width={0}
              height={0}
            />

            <div className="flex flex-col lg:justify-around">
              <div className="  lg:text-[20px] md:text-[18px] text-base text-gray-600 lg:mb-[30px] md:mb-[20px] mb-[15px] mt-[10px] md:mt-[0px]">
                {outsource?.primary?.label?.text}
              </div>

              <div className="text-purple-1000 lg:text-[36px] md:text-[30px] text-2xl lg:leading-9   lg:mb-[30px] md:mb-[20px] mb-[15px]">
                {outsource?.primary?.heading?.text}
              </div>
              <div className="text-gray-600 lg:text-[20px] md:text-[18px] text-base   lg:mb-[30px] md:mb-[20px] mb-[15px] max-w-[350px]">
                {outsource?.primary?.description?.text}
              </div>
              <a
                href={outsource?.primary?.learn_more?.url || ""}
                className="flex items-center  lg:mb-[30px] md:mb-[20px] mb-[15px]"
              >
                <span className="  pr-2 lg:text-[22px] md:text-[19px] text-[17px] text-purple-500 font-bold">
                  Learn more
                </span>
                <img
                  src={forwardIcon}
                  alt="forward-icon"
                  className="w-auto h-auto"
                  width={0}
                  height={0}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col rounded-2.5xl lg:max-w-[483px] max-w-[400px] md:max-w-[700px] w-full bg-[#F5F5FF]  pt-[30px] justify-between mx-auto lg:mt-0 mt-4">
          <div className="  lg:text-[20px] md:text-[18px] text-base text-gray-600 px-[20px] lg:mb-[30px] md:mb-[20px] mb-[15px]">
            {labelling_types?.primary?.label?.text}
          </div>
          <div className="text-purple-1000 lg:text-[36px] md:text-[30px] text-2xl lg:leading-9   px-[20px] lg:mb-[30px] md:mb-[20px] mb-[15px]">
            {labelling_types?.primary?.heading?.text}
          </div>
          <div className="text-gray-600 lg:text-[20px] md:text-[18px] text-base   px-[20px] mb-[30px]">
            {labelling_types?.primary?.description?.text}
          </div>
          <div className="flex flex-col px-[20px] lg:mb-[30px] md:mb-[20px] mb-[10px]">
            {labelling_types?.items
              ?.reduce(function (result, value, index, array) {
                if (index % 2 === 0) result.push(array.slice(index, index + 2));
                return result;
              }, [])
              .map((elem) => {
                const [description1, description2] = elem;
                return (
                  <div className="flex justify-between lg:justify-start">
                    <div className="flex mb-[5px] items-center w-[50%]">
                      <img
                        src={tickIcon}
                        alt="ticket-icon"
                        loading="lazy"
                        className="w-auto h-auto tick-icon"
                        width={0}
                        height={0}
                      />
                      <div className="lg:px-[10px] md:px-[7px] px-[3px] lg:py-[2px] py-[0px]   font-medium lg:text-[15px] md:text-[15px] text-[9px] text-purple-500 mr-[9px] cursor-pointer">
                        {description1?.slide_text?.text}
                      </div>
                    </div>

                    <div className="flex mb-[5px] items-center w-[50%]">
                      <img
                        src={tickIcon}
                        alt="ticket-icon"
                        loading="lazy"
                        className="w-auto h-auto tick-icon"
                        width={0}
                        height={0}
                      />
                      <div className="lg:px-[10px] md:px-[7px] px-[3px] lg:py-[2px] py-[0px]   font-medium lg:text-[15px] md:text-[15px] text-[9px] text-purple-500 mr-[9px] cursor-pointer">
                        {description2?.slide_text?.text}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="min-h-[18px] bg-[#BDBEFF]" />

          {_data?.hero_section_video?.url ? (
            <img
              className="w-auto h-auto"
              width={0}
              height={0}
              loading="lazy"
              alt="mast group"
              src="https://images.prismic.io/encord/552840f1-9ee0-4282-b1fe-8f850f32742a_Mask%20group.png?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max"
            />
          ) : (
            <VideoComponent loop autoPlay muted>
              <source
                src={
                  "https://prismic-io.s3.amazonaws.com/encord/d9ff90d4-d15f-409b-a2a3-65200e66ab45_Labelling+Toolkit+for+Comparison+PAGE.mp4"
                }
                type="video/mp4"
              />
            </VideoComponent>
          )}
        </div>
      </div>

      <article className="bg-[#F9F9F9] lg:my-[30px] md:my-[20px] my-[15px] lg:py-[20px] md:py-[15px] py-[20px]">
        <SideBySideSection
          btnText="Read more"
          description={single_review?.primary?.description?.text}
          hrefLink={single_review?.primary?.read_more?.url}
          sideImage={single_review?.primary?.section_image?.url}
          sideVideo={single_review?.primary?.section_video?.url}
          isSubDescription={true}
          subDescriptionOne={single_review?.primary?.author?.text}
          subDescriptionTwo={single_review?.primary?.role?.text}
          title={single_review?.primary?.heading?.text}
          isEndCommas={true}
          isStartCommas={true}
        />
      </article>

      <article>
        <CustomTag
          bgColor="#FAF4FF"
          borderColor="#9254DE"
          customIcon={encord_active?.primary?.label_icon?.url}
          textColor="#9254DE"
          title={encord_active?.primary?.label?.text}
        />

        <SectionalSection
          textColor="#34344B"
          webPaddingTop={8}
          mobPaddingTop={8}
          isVideo={!!_data?.hero_section_video?.url}
          isMediumSize={true}
          title={encord_active?.primary?.heading?.html}
          description={encord_active?.primary?.description?.text}
        />
      </article>

      <article className="flex lg:flex-row md:flex-col flex-col mx-auto lg:max-w-[1136px] md:max-w-[700px] max-w-[400px] lg:mb-[22px] md:mb-[30px] mb-[20px] w-full  lg:justify-between">
        <SideCard
          btnText={`Learn more`}
          description={data_and_model_quality?.primary?.description?.text}
          hrefLink={data_and_model_quality?.primary?.learn_more?.url}
          img={data_and_model_quality?.primary?.section_image?.url}
          subTitle={data_and_model_quality?.primary?.label?.text}
          title={data_and_model_quality?.primary?.heading?.text}
        />

        <SideCard
          btnText={`Learn more`}
          description={discover_label_errors?.primary?.description?.text}
          hrefLink={discover_label_errors?.primary?.learn_more?.url}
          img={discover_label_errors?.primary?.section_image?.url}
          subTitle={discover_label_errors?.primary?.label?.text}
          title={discover_label_errors?.primary?.heading?.text}
        />
      </article>

      <article>
        <RowByRowSection
          isHeaderText={true}
          headerText={active_learning_pipelines?.primary?.label?.text}
          btnText="Learn more"
          description={active_learning_pipelines?.primary?.description?.text}
          hrefLink={active_learning_pipelines?.primary?.learn_more?.url}
          sideImage={active_learning_pipelines?.primary?.section_image?.url}
          title={active_learning_pipelines?.primary?.heading?.text}
          shadow="0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)"
          bRadius="41px"
        />
      </article>

      <article className="slider-gradiant lg:my-[30px] md:my-[20px] my-[15px] overflow-hidden">
        <section className="section_container mx-auto z-10 relative lg:py-[40px] md:py-[30px] py-[20px]">
          <img
            src={sliderBackgroundImage}
            alt="img"
            className="absolute w-auto h-auto slider-background-image"
            loading="lazy"
            width={0}
            height={0}
          />
          <section className="px-[20px] lg:py-[80px] py-[20px]">
            <Slider {...newCardSliderSettings}>
              {testimonial?.items?.map((value, index) => {
                return (
                  <div
                    key={index}
                    className="min-h-[343px] mr-5 user_card lg:px-6 md:px-4 px-3 lg:py-5 md:py-4 py-3 !flex flex-col justify-between slider-card-gradiant shadow-xl"
                  >
                    <div className="flex md:flex-row flex-col justify-between mb-[30px]">
                      <img
                        src={ratingImage}
                        className="mb-[10px] md:mb-0 h-5"
                        alt="rating"
                        loading="lazy"
                        width={100}
                        height={0}
                      />
                      <img
                        src={value?.avatar?.url}
                        alt="profile img"
                        width={100}
                        loading="lazy"
                        className=""
                        height={0}
                      />
                    </div>
                    <p className="text-base text-purple-1300 line-clamp-5">
                      {value?.comment?.text}
                    </p>

                    <section className="flex items-center justify-between mt-2 lg:mt-10 md:mt-3">
                      <section className="text-purple-1300">
                        <p className="text-base lg:leading-7 md:leading-[25px] leading-[20px] font-semibold line-clamp-1">
                          {value?.name?.text}
                        </p>
                        <p className="text-sm lg:leading-6 md:leading-[25px] leading-[20px] mt-[11px] line-clamp-2">
                          {value?.role?.text}
                        </p>
                      </section>

                      <img
                        src={value?.author_avatar?.url}
                        alt="avatar img"
                        loading="lazy"
                        className="w-12 h-12 rounded-full"
                        width={0}
                        height={0}
                      />
                    </section>
                  </div>
                );
              })}
            </Slider>
          </section>
        </section>
      </article>

      <article>
        <CustomTag
          bgColor="#E6F7FF"
          borderColor="#096DD9"
          customIcon={data_and_security?.primary?.label_logo?.url}
          textColor="#096DD9"
          title={data_and_security?.primary?.label?.text}
        />

        <SectionalSection
          textColor="#34344B"
          webPaddingTop={8}
          mobPaddingTop={8}
          isVideo={!!_data?.hero_section_video?.url}
          title={data_and_security?.primary?.heading?.html}
          description={data_and_security?.primary?.description?.text}
        />
      </article>

      <article className="lg:py-[30px] md:py-[20px] py-[15px]">
        <RowByRowSection
          isHeaderText={true}
          headerText={api_and_sdk?.primary?.label?.text}
          btnText="Read more"
          description={api_and_sdk?.primary?.description?.text}
          hrefLink={api_and_sdk?.primary?.read_more?.url}
          sideImage={api_and_sdk?.primary?.section_image?.url}
          sideVideo={api_and_sdk?.primary?.section_video?.url}
          title={api_and_sdk?.primary?.heading?.text}
          shadow="0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)"
          bRadius="41px"
        />
      </article>

      <article className="bg-[#F9FBFF]">
        <RowByRowSection
          isHeaderText={true}
          headerText={workflows?.primary?.label?.text}
          btnText="Read more"
          description={workflows?.primary?.description?.text}
          hrefLink={workflows?.primary?.read_more?.url}
          sideImage={workflows?.primary?.section_image?.url}
          title={workflows?.primary?.heading?.text}
        />
      </article>

      <div className="security-gradiant lg:py-[30px] md:py-[20px] py-[15px]">
        <article className="flex lg:flex-row md:flex-col flex-col mx-auto lg:max-w-[1136px] md:max-w-[700px] max-w-[400px] lg:mb-[100px] md:mb-[40px] w-full lg:justify-between">
          <SideCard
            btnText={`Read more`}
            description={security_at_encord?.primary?.description?.text}
            hrefLink={security_at_encord?.primary?.read_more?.url}
            img={security_at_encord?.primary?.section_image?.url}
            subTitle={security_at_encord?.primary?.label?.text}
            title={security_at_encord?.primary?.heading?.text}
          />

          <SideCard
            btnText={`Read more`}
            description={integrations?.primary?.description?.text}
            hrefLink={integrations?.primary?.read_more?.url}
            img={integrations?.primary?.section_image?.url}
            subTitle={integrations?.primary?.label?.text}
            title={integrations?.primary?.heading?.text}
          />
        </article>
      </div>

      <div className="footer_hero_gradient lg:max-w-[1136px] md:max-w-[700px] max-w-[400px] mx-auto lg:rounded-[25px] md:rounded-[15px] rounded-[10px]">
        <SectionalSection
          isBtnColor={true}
          textColor="white"
          webPaddingTop={16}
          mobPaddingTop={8}
          title={_data?.cta_heading?.html}
          description={_data?.cta_description?.text}
        />
      </div>
    </section>
  );
};

const CompetitorNestedLandingPage = (props) => {
  return (
    <Layout location={props?.location}>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <PageContent {...props} />
    </Layout>
  );
};

export const query = graphql`
  query PrismicNestedCompetitorLandingPage($uid: String!) {
    allPrismicNestedCompetitorLandingPage(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        data {
          body {
            ... on PrismicNestedCompetitorLandingPageDataBodyActiveLearningPipelines {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                learn_more {
                  url
                }
                section_image {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyAiPoweredLabeling {
              id
              slice_type
              primary {
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                description {
                  html
                  text
                }
                learn_more_text {
                  html
                  text
                }
                tag {
                  html
                  text
                }
              }
              items {
                slider_image {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyAnnotateSection {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                label_icon {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyApiAndSdk {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                read_more {
                  url
                }
                label {
                  html
                  text
                }
                section_image {
                  alt
                  url
                }
                section_video {
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyDataAndModelQuality {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                label {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                learn_more {
                  url
                }
                section_image {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyDataAndSecurity {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                label_logo {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyDiscoverLabelErrors {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                learn_more {
                  url
                }
                section_image {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyEncordActive {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                label {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label_icon {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyFeatureTable {
              id
              slice_type
              primary {
                competitor_logo {
                  alt
                  url
                }
                encord_logo {
                  alt
                  url
                }
                heading {
                  html
                  text
                }
              }
              items {
                competitor_score {
                  html
                  text
                }
                encord_score {
                  html
                  text
                }
                feature_name {
                  html
                  text
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyIntegrations {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                read_more {
                  url
                }
                section_image {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyLabellingTypes {
              id
              slice_type
              items {
                slide_text {
                  html
                  text
                }
                slider_image {
                  alt
                  url
                }
                slider_video {
                  url
                }
              }
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyOutsource {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                learn_more {
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodySecurityAtEncord {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                read_more {
                  url
                }
                section_image {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodySingleReview {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                author {
                  html
                  text
                }
                role {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                read_more {
                  url
                }
                section_image {
                  alt
                  url
                }
                section_video {
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyTestimonial {
              id
              slice_type
              items {
                avatar {
                  alt
                  url
                }
                author_avatar {
                  alt
                  url
                }
                comment {
                  html
                  text
                }
                name {
                  html
                  text
                }
                role {
                  html
                  text
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyTrustedBrandsList {
              id
              slice_type
              primary {
                label {
                  html
                  text
                }
              }
              items {
                brand_image {
                  alt
                  url
                }
              }
            }
            ... on PrismicNestedCompetitorLandingPageDataBodyWorkflows {
              id
              slice_type
              primary {
                description {
                  html
                  text
                }
                heading {
                  html
                  text
                }
                label {
                  html
                  text
                }
                read_more {
                  url
                }
                section_image {
                  alt
                  url
                }
              }
            }
          }
          cta_description {
            html
            text
          }
          cta_heading {
            html
            text
          }
          meta_description {
            html
            text
          }
          hero_section_video {
            url
          }
          meta_heading {
            html
            text
          }
          page_description {
            html
            text
          }
          page_heading {
            html
            text
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data =
    data?.allPrismicNestedCompetitorLandingPage &&
    !!data.allPrismicNestedCompetitorLandingPage?.nodes.length &&
    data.allPrismicNestedCompetitorLandingPage?.nodes[0]?.data;
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default CompetitorNestedLandingPage;
